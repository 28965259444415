<template>
  <div class="tournamentItem" >
    <div :class="[{'absent': team.is_absent}, 'memberCount']">
      {{ team.team_mates.length + "/" + team_size }}
    </div>
    <div class="teamInfo">
      <h2>{{ (team_size > 1) ? team.team_name : team.team_mates[0].player_name }}</h2>
      <p v-if="team_size > 1">{{ team.team_mates.map(value => value.player_name).join(", ") }}</p>
    </div>
    <div class="join">
      <IconButton button-type="fluid" :button-function="()=>!isWinner?setWinnerFunction(team.team_id):null"
                  v-if="(team.tournament.current_registration_phase===4&&showWinnerBtn)||isWinner">
        <div :class="isWinner?'material-icons':'material-icons-outlined'">
          emoji_events
        </div>
      </IconButton>

      <IconButton button-type="fluid" :button-function="()=>goToTeamView()" v-if="isInTeam&&team_size > 1">
        <div class="material-icons">
          build
        </div>
      </IconButton>
      
      <img v-else-if="team_size > 1"
           :src="isPrivate?require('../../assets/icons/locks/lockSymbolClosed.svg'):require('../../assets/icons/locks/lockSymbolOpen.svg')">
      <PlainButton :button-function="()=>joinFunction(team)" v-if="!isInTeam && team_size > 1&&showBtn">Beitreten
      </PlainButton>
      <PlainButton button-type="cancel" :button-function="()=>leaveFunction(team)" v-else-if="isInTeam&&showBtn">
        Verlassen
      </PlainButton>

      <ContextDialog v-if="showMoreButton">
        <TextButton :but-fun="()=> setTeamAbsence(team.team_id)">{{ team.is_absent?'Anwesend':'Abwesend' }}</TextButton>
        <TextButton :but-fun="()=> goToTeamView()">Details</TextButton>
      </ContextDialog>
    </div>
  </div>
</template>
<script>
import PlainButton from "@/components/Buttons/PlainButton";
import IconButton from "@/components/Buttons/IconButton";
import TextButton from "../Buttons/TextButton";
import ContextDialog from "../Dialogs/ContextDialog";

export default {
  name: "TeamItem",
  components: {ContextDialog, TextButton, IconButton, PlainButton},
  props: {
    isInTeam: Boolean,
    isPrivate: Boolean,
    isWinner: Boolean,
    showWinnerBtn: Boolean,
    team_size: Number,
    team: Object,
    joinFunction: Function,
    goToTeamView: Function,
    setTeamAbsence: Function,
    leaveFunction: Function,
    setWinnerFunction: Function,
    showBtn: {
      type: Boolean,
      default: true
    },
    showMoreButton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>

@import "../../styles/themes.scss";

.icon {
  display: flex;
  color: white;
  font-size: 3em;
  align-items: center;
}

.tournamentItem {

  @include theme {
    background-color: theme-get('background-color-1');
    box-shadow: theme-get('shadow-2');
  }
  min-width: 0;
  border-radius: 16px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  .memberCount {

    position: relative;
    height: 72px;
    aspect-ratio: 1;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 72px;
    font-size: 1.5em;
    font-weight: 600;
    @include theme {
      color: theme-get('primary-color');
      background-color: theme-get('primary-background')
    }
  }

  .teamInfo {
    text-align: left;
    min-width: 0;
    display: block;


    width: 100%;

    h2 {
      @include theme {
        color: theme-get('header-2')
      }
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 2em;
      margin: 0;
    }

    p {
      @include theme {
        color: theme-get('text-2')
      }
      margin: 0;
    }

  }

  .join {
    gap: 8px;
    display: flex;
    align-items: center;
    align-self: center;
    height: min-content;
  }
}

@media screen and (max-width: 1024px) {
  .tournamentItem {
    flex-direction: column;

    .teamInfo {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      align-items: center;
    }

    .join {
      width: 100%;

      flex-direction: row-reverse;
    }
  }
}

@media screen and (max-width: 480px) {
  .tournamentItem {
    width: 100%;
    min-width: 256px;

    .teamInfo {
      h2 {
        font-size: 1.5em;
      }
    }
  ;
  }
}

.absent {
  filter: grayscale(1);
}

</style>
