<template>
  <div id="contextDialog">
    <HoverIconButton class="hoverButton" button-type="fluid" :mouse-leave-function="()=>showMoreButtonDialog=false" :mouse-over-function="()=>showMoreButtonDialog=true" :is-scaling="false">
      <div class="material-icons">
        more_vert
      </div>

      <div v-if="showMoreButtonDialog" class="moreButtonDialog">
        <slot></slot>
      </div>
    </HoverIconButton>
  </div>
</template>

<script>
import HoverIconButton from "../Buttons/HoverIconButton";

export default {
  name: "ContextDialog",
  components: { HoverIconButton },
  data() {
    return {
      showMoreButtonDialog: false,
    }
  }
}
</script>

<style scoped>
.hoverButton {
  position: relative
}

.moreButtonDialog {
  bottom: 0;
  right: 0;
  transform: translate(75%,75%);
  position: absolute;

  padding: 5px;
  border-radius: 10px;
  width: max-content;
  background-color: #1C1E21;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
</style>