<template>
  <button id="solidButton"
          :class="[{'disabled':isDisabled}, buttonType, {'scaling':isScaling}]"
          @mouseover="mouseOverFunction"
          @mouseleave="mouseLeaveFunction">
    <slot class="button-content"></slot>
  </button>
</template>

<script>
export default {
  name: "HoverIconButton",
  props: {
    isDisabled: Boolean,
    isScaling: {
      type: Boolean,
      default: true,
    },
    mouseOverFunction: Function,
    mouseLeaveFunction: Function,
    buttonType: {
      type: String,
      default: 'normal',
      validator: t => ['cancel', 'success', 'normal', 'fluid'].includes(t)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";

.disabled {
  pointer-events: none;
  filter: grayscale(0.8);
}


//Color Definitions
.success{
  @include theme() {
    background-color: theme-get('success');
  }

  color:white
}

.cancel{
  background-color: #D55353;
  color:white
}

.fluid{
  @include theme(){
    background-color: theme-get('primary-background');
    color: theme-get('primary-color')
  }
}
.normal{
  color:white;
  @include theme() {
    background-color: theme-get('primary-color');
    box-shadow: theme-get('shadow-1');
  }
}

#solidButton {
  box-sizing: border-box;
  transition: 0.1s;
  cursor: pointer;
  user-select: none;
  border: none;
  height: max-content;
  width: max-content;
  padding:8px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1em;

  .button-content {
    width: auto;
    line-break: strict;
  }
}

.scaling {
  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
}
</style>
