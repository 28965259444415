<template>
  <div class="errorDialog">
    <div class="infoText">
      <div class="material-icons warningIcon" v-if="isWarning">
        warning
      </div>
      <div class="material-icons errorIcon" v-else>
        error
      </div>
      <div>
        <h1>{{dialogTitle}}</h1>
        <p>
          <slot>

          </slot>
        </p>
      </div>
    </div>
    <div class="actions">
      <PlainButton :button-function="closeFunction" v-if="showCloseButton" button-type="fluid">
        {{ cancelButtonText }}
      </PlainButton>
      <PlainButton  :button-function="confirmFunction" >
        {{buttonText}}
      </PlainButton>
    </div>
  </div>
</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton";
export default {
  name: "ErrorDialog",
  components: {PlainButton},
  props:{
    dialogTitle:String,
    buttonText:String,
    cancelButtonText:{
      default:"Abbrechen"
    },
    closeFunction:Function,
    confirmFunction:Function,
    isWarning:Boolean,
    showCloseButton:Boolean,
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";
  .errorDialog{
    box-shadow: 0 5px 20px rgba(0,0,0,0.15);
    position: fixed;
    display: flex;
    flex-direction: column;
    padding:32px;
    align-items: center;
    z-index:101;
    left: 50%;
    top: 50%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    height: auto;
    @include theme(){
      background-color: theme-get('background-color-2');
    }
    background-color: white;
    border-radius: 16px;
    .infoText{
      h1{
        @include theme(){
          color: theme-get('header-1');
        }
        margin:0;
      }
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin:  8px 0;
      gap: 24px;
      display: flex;
      justify-content: flex-start;
      p{
        margin:0;
        @include theme(){
          color: theme-get('text-1');
        }
      }
    }
    .actions{
      width: 100%;
      margin-top: 16px;
      display: flex;
      gap:16px;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column-reverse;
    }
  }
  .errorIcon{
    padding:16px;
    font-size: 2em;
    color:#D55353;
    background-color:hsla(0, 61%, 58%,0.15);
    border-radius: 100%;
  }
  .warningIcon{
    padding:16px;
    font-size: 2em;
    color:#E9A727;
    background-color:hsla(40, 82%, 53%,0.15);
    border-radius: 100%;
  }
  @media screen and (max-width: 1024px){
    .actions{
      margin-top: 32px !important;

    }
  }
  @media screen and (max-width: 768px){
    .errorDialog{
      h1{
        font-size: 2em;
      }
      .infoText{
        .material-icons{
          font-size: 3em;
        }
        color: #444444;

      }

    }
  }

</style>
